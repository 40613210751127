.filterForm {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.filterForm__select {
  background: #aeaeae;
  border-radius: 5px;
  font-size: 14px;
  height: 25px;
  margin-bottom: 3px;
}

.filterForm__input {
  font-size: 14px;
  border-radius: 5px;
  height: 20px;
}

@media (max-width: 740px) {
  .filterForm {
    align-self: baseline;
    margin: 0;
  }
}

@media (max-width: 580px) {
  .filterForm {
    align-self: baseline;
    margin: 0;
  }
}
