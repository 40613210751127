.table {
  background: #303030;
  border-spacing: 0;
  padding: 7px;
  border-radius: 7px;
  text-align: center;
}

.table__row td {
  height: 28px;

}

.table__head-row > th {
  padding-bottom: 5px;
  font-weight: normal;
  color: #868686;
  position: relative;
  transition: all 0.3s;
  min-width: 130px;
}

.table__head-row > th:hover {
  cursor: pointer;
  color: #fff;
}

.table__head-row > th:hover[data-title="date"] {
  cursor: default;
  color: #868686;
}

.table__row:nth-of-type(odd) {
  background: #404040;
}

.table__sort-logo {
  width: 21px;
  margin-left: 3px;
  position: absolute;
  bottom: 5px;
  opacity: 0;
  transition: all 0.3s;
}

.table__sort-logo_active {
  opacity: 0.6;
}

@media (max-width: 580px) {
  .table {
    font-size: 14px;
  }

  .table__head-row > th {
    min-width: 85px;
  }

  .table__sort-logo {
    display: none;
  }
}
