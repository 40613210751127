.page {
  min-height: 100vh;
  background-color: rgb(35 35 35);
  font-family: Century Gothic, Arial, Helvetica, Sans-serif;
  color: #bdc1c6;
}

.page__container {
  margin: auto;
}


